import Vue from 'vue';
import App from './App';
import { Carousel,CarouselItem,Image,Dialog } from 'element-ui';

import '@/icons' 

import { Lazyload } from 'vant';
import { router } from './router';
import * as filters from './filters' // global filters
Vue.config.ignoredElements = ['wx-open-launch-weapp'];

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.component(Image.name, Image);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Dialog.name, Dialog);

Vue.use(Lazyload)
// 非生产环境, 适配mockjs模拟数据                 // api: https://github.com/nuysoft/Mock
if (process.env.NODE_ENV !== 'production') {
  require('@/mock')
}
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
