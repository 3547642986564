<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>
<script>
import { setToken,removeToken,getToken } from '@/utils/auth'
import { goPage } from '@/utils/'
import { Tabbar, TabbarItem } from 'vant';
export default {
   components:{
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data(){
    return {
      isShowTabbar:false,
    }
  },
  mounted() {
  },
  methods: {
    // 处理TOKEN
    handleToken(params){
     const {token,expires} = JSON.parse(params)
     const host = location.host
     setToken(token, expires, host)
     const hasToken = getToken()
     const newParams = hasToken ? 'true' : 'false'
     goPage('confirmTokenStatus',newParams)
    },
    //清除TOKEN
    clearH5Token(){
      removeToken()
    }
  },
}
</script>
<style>
html {
  margin:0;
  padding:0;
  background-color: #fff;
}
.column-title {
  font-size:16px;
  font-weight: 600;
  padding-top:16px;
  padding-left:16px;
}
.el-image-viewer__mask {
  opacity: 1!important;
}
body {
  font-size: 16px;
  /* background: #eff2f5; */
  -webkit-font-smoothing: antialiased;
  margin:0 auto;
   text-align: center;
   width:375px;
  padding:0;
  overflow-x: hidden;
}
.app {
   width:100%;
}
.tac {
  text-align: center;
}
.tal {
  text-align: left;
}
.pd10 {
  padding: 10px;
}
.mt20 {
  margin-top:20px;
}
.mt10 {
  margin-top:10px;
}
</style>
