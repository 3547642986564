import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token, expires = 7,host) {
  return Cookies.set(TokenKey, token, {expires, host})
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}
