import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },{
    name: 'home',
    component: () => import('./view/home/index'),
    meta: {
      title: '沈阳综合保税区桃仙园区'
    }
  },{
    name: 'biz1210',
    component: () => import('./view/business/b1210'),
    meta: {
      title: '业务模式 1210 与 9610'
    }
  },{
    name: 'storeHouse',
    component: () => import('./view/business/store-house'),
    meta: {
      title: '沈阳自贸区保税仓'
    }
  },
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({  mode: 'hash', routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
